import type { Control } from "react-hook-form";

import { Plus } from "lucide-react";
import { Controller } from "react-hook-form";

import { Button } from "@/components/button";
import { Input, Label, TextField } from "@/components/field";

import type { LinkFormValues } from "../useLinkForm";

type InsertLinkFormProps = {
  control: Control<LinkFormValues>;
  onSubmit: () => void;
};

export function InsertLinkForm({ control, onSubmit }: InsertLinkFormProps) {
  return (
    <Controller
      control={control}
      name="link"
      render={({
        field: { ref, ...field },
        fieldState: { error, invalid },
      }) => (
        <TextField className="flex-1" isInvalid={invalid} {...field}>
          <Label>Add links</Label>
          <div className="flex items-end gap-xl">
            <Input placeholder="Insert Link" ref={ref} />
            <Button
              aria-label="Add new link"
              isIconOnly
              onPress={onSubmit}
              size="lg"
              type="button"
              variant="secondary"
            >
              <Plus />
            </Button>
          </div>
          {error && (
            <span className="text-sm text-red-500">{error.message}</span>
          )}
        </TextField>
      )}
    />
  );
}

import Cookies from "js-cookie";
import { useState } from "react";

const COOKIE_NAME = "sidebarClosed";
const COOKIE_EXPIRES = 30; // 30 days

export function useSidebarCollapsed(): {
  handleToggleCollapse: (collapsed: boolean) => void;
  isCollapsed: boolean;
} {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const savedState = Cookies.get(COOKIE_NAME);

    return savedState === "true";
  });

  const handleToggleCollapse = (collapsed: boolean) => {
    setIsCollapsed(collapsed);
    if (collapsed) {
      Cookies.set(COOKIE_NAME, "true", {
        expires: COOKIE_EXPIRES,
        path: "/",
        sameSite: "lax",
      });
    } else {
      Cookies.remove(COOKIE_NAME, { path: "/" });
    }
  };

  return {
    handleToggleCollapse,
    isCollapsed,
  };
}

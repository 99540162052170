import type { UseFormReturn, UseFormSetError } from "react-hook-form";

import { nanoid } from "nanoid";

import type {
  CreateRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateRequestForm";
import type { LinkFormValues } from "../useLinkForm";

import { MAX_FILES } from "../utils/uploadUtils";

type AssetField = "assets" | "inspirations";

export function useLinkManagement(
  field: AssetField,
  assets: (FileAsset | LinkAsset)[],
  methods: UseFormReturn<CreateRequestFormData>,
  setError: (error: null | string) => void,
  setLinkFormError: UseFormSetError<LinkFormValues>,
): {
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
} {
  const handleLinkAdd = (url: { link: string }) => {
    if (assets.length >= MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      // Hack to set useLinkForm formState.isSubmitSuccessful to false and prevent it from resetting the form
      setLinkFormError("root", {
        type: "added-assets-limit-reached",
      });

      return;
    }

    methods.setValue(field, [{ id: nanoid(), url: url.link }, ...assets]);
  };

  const handleLinkDelete = (linkId: string) => {
    setError(null);

    const filteredAssets = assets.filter((asset) => asset.id !== linkId);

    methods.setValue(field, filteredAssets);
  };

  return { handleLinkAdd, handleLinkDelete };
}

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { PostHogProvider } from "posthog-js/react";

import { routeTree } from "@/app/routeTree.gen";
import { env } from "@/config/env";

const queryClient = new QueryClient();

// Set up a Router instance
const router = createRouter({
  context: {
    queryClient,
  },
  defaultPreload: "intent",
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  // Since we're using React Query, we don't want loader calls to ever be stale
  routeTree,
});

// Set up Sentry
Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
  tracesSampleRate: 0.2, // TODO: Adjust this value in production
});

// Register things for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const posthogOptions = {
  api_host: env.VITE_PUBLIC_POSTHOG_HOST,
  ui_host: "https://us.posthog.com",
};

function AppRouter() {
  return (
    <PostHogProvider
      apiKey={env.VITE_PUBLIC_POSTHOG_KEY}
      options={posthogOptions}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </QueryClientProvider>
    </PostHogProvider>
  );
}

export function App() {
  return <AppRouter />;
}

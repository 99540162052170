import Award from "./icons/award.svg?react";
import BarChart from "./icons/bar-chart.svg?react";
import Bell from "./icons/bell.svg?react";
import BookClosed from "./icons/book-closed.svg?react";
import BookOpen from "./icons/book-open.svg?react";
import BriefCase from "./icons/briefcase.svg?react";
import GraduationCap from "./icons/graduation-hat.svg?react";
import Heart from "./icons/heart.svg?react";
import HelpCircle from "./icons/help-circle.svg?react";
import Home from "./icons/home.svg?react";
import Layers from "./icons/layers-two.svg?react";
import Settings from "./icons/settings.svg?react";
import Trash from "./icons/trash.svg?react";
import User from "./icons/user.svg?react";
import Users from "./icons/users.svg?react";

const ownerNavItems = [
  { href: "/requests?active=1", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/users", icon: User, label: "Users" },
  { href: "/profile/designer-preference", icon: Heart, label: "My designers" },
  // { href: "/my-files", icon: Folder, label: "My files" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Design library",
  },
  { href: "/nolimit-university", icon: GraduationCap, label: "University" },
] as const;

const adminNavItems = [
  { href: "/requests?active=1", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/users", icon: User, label: "Users" },
  { href: "/profile/designer-preference", icon: Heart, label: "My designers" },
  // { href: "/my-files", icon: Folder, label: "My files" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Design library",
  },
  { href: "/nolimit-university", icon: GraduationCap, label: "University" },
] as const;

const superAdminNavItems = [
  { href: "/dashboard", icon: Home, label: "Dashboard" },
  { href: "/requests", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/users", icon: User, label: "Users" },
  { href: "/teams", icon: Users, label: "Teams" },
  { href: "/projects", icon: BriefCase, label: "Accounts" },
  { href: "/settings", icon: Settings, label: "Settings" },
  { href: "/issues-reports", icon: BarChart, label: "Feedback" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Inspiration",
  },
  { href: "https://help.nlc.com/en/", icon: BookOpen, label: "Knowledge" },
  { href: "/nolimit-university", icon: GraduationCap, label: "University" },
  {
    href: "/help-section/articles/user-guides",
    icon: HelpCircle,
    label: "Use Guide",
  },
  { href: "/trash", icon: Trash, label: "Trash" },
] as const;

const desginerNavItems = [
  { href: "/dashboard", icon: Home, label: "Dashboard" },
  { href: "/requests", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/projects", icon: BriefCase, label: "Accounts" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Inspiration",
  },
  {
    href: "https://nlc.com/nolimit-university/",
    icon: GraduationCap,
    label: "University",
  },
];

const accountManagerNavItems = [
  { href: "/dashboard", icon: Home, label: "Dashboard" },
  { href: "/requests", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/users", icon: User, label: "Users" }, // ??
  { href: "/projects", icon: BriefCase, label: "Accounts" },
  { href: "/issues-reports", icon: BarChart, label: "Feedback" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Inspiration",
  },
  { href: "https://help.nlc.com/en/", icon: BookOpen, label: "Knowledge" },
  {
    href: "https://nlc.com/nolimit-university/",
    icon: GraduationCap,
    label: "University",
  },
] as const;

const teamLeaderNavItems = [
  { href: "/dashboard", icon: Home, label: "Dashboard" },
  { href: "/requests", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/users", icon: User, label: "Users" }, // ?
  { href: "/projects", icon: BriefCase, label: "Accounts" },
  { href: "/issues-reports", icon: BarChart, label: "Feedback" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Inspiration",
  },
  { href: "https://help.nlc.com/en/", icon: BookOpen, label: "Knowledge" },
  {
    href: "https://nlc.com/nolimit-university/",
    icon: GraduationCap,
    label: "University",
  },
] as const;

const collaboratorNavItems = [
  { href: "/requests", icon: Layers, label: "Requests" },
  {
    href: "/notifications-v2?unread=false",
    icon: Bell,
    label: "Notifications",
  },
  { href: "/brands", icon: Award, label: "Brands" },
  { href: "/profile/designer-preference", icon: Heart, label: "My designers" },
  {
    href: "https://drive.google.com/drive/folders/1JwqCAJHjFBmS02gAkHrcOtTHFQF-fdPk",
    icon: BookClosed,
    label: "Design library",
  },
  {
    href: "https://nlc.com/nolimit-university/",
    icon: GraduationCap,
    label: "University",
  },
] as const;

export const NAV_ITEMS = {
  "account-manager": accountManagerNavItems,
  admin: adminNavItems,
  collaborator: collaboratorNavItems,
  designer: desginerNavItems,
  owner: ownerNavItems,
  "super-admin": superAdminNavItems,
  "team-leader": teamLeaderNavItems,
} as const;

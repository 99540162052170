import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { Controller } from "react-hook-form";
import { useListData } from "react-stately";

import { Label } from "@/components/field";
import {
  MultiSelect,
  MultiSelectField,
  MultiSelectItem,
} from "@/components/multi-select";
import { Select, SelectButton } from "@/components/select";
import { Tag } from "@/components/tag-group";
import apiClient from "@/lib/api/client";

import { useCreateRequestFormContext } from "../useCreateRequestForm";

// TODO: replace with shared types when ready
type PreferredDesigner = {
  id: string;
  textValue: string;
};

type Response = {
  preferredDesigners: {
    id: string;
    name: string;
  }[];
};

type MappedResponse = PreferredDesigner[];

function useGetPreferredDesigners(projectId: number) {
  return useQuery<Response, DefaultError, MappedResponse>({
    queryFn: async () =>
      await apiClient
        .url(`/projects/${projectId}/preferred-designers`)
        .get()
        .json(),
    queryKey: ["preferred-designers"],
    select: (data) =>
      data.preferredDesigners.map(({ id, name }) => ({ id, textValue: name })),
  });
}

export function PreferredDesignersSelect() {
  const methods = useCreateRequestFormContext();

  const { ["project-id"]: projectId } = useSearch({
    from: "/requests/_layout/create",
  });

  const { data } = useGetPreferredDesigners(projectId);

  const selectedList = useListData<PreferredDesigner>({
    initialItems: [],
  });

  if (data) {
    return (
      <Controller
        control={methods.control}
        name="preferredDesigners"
        render={({ field: { name, onBlur, onChange, value } }) => (
          <MultiSelectField>
            <Label>Preferred designers</Label>
            <MultiSelect
              items={data}
              name={name}
              onBlur={onBlur}
              onItemAdd={(key) => {
                const newValue = [...(value || []), key.toString()];

                onChange(newValue);
              }}
              onItemRemove={(key) => {
                const newValue = (value || []).filter(
                  (id) => id !== key.toString(),
                );

                onChange(newValue);
              }}
              placeholder="Select"
              renderEmptyState={(inputValue) => (
                <span className="p-2">
                  {inputValue ? (
                    <>No results found for: {inputValue}</>
                  ) : (
                    `No designers available`
                  )}
                </span>
              )}
              selectedList={selectedList}
              tag={(item) => <Tag textValue={item.id}>{item.textValue}</Tag>}
            >
              {(item) => (
                <MultiSelectItem textValue={item.id}>
                  {item.textValue}
                </MultiSelectItem>
              )}
            </MultiSelect>
          </MultiSelectField>
        )}
      />
    );
  }

  return (
    <Select placeholder="Loading...">
      <Label>Preferred designers</Label>
      <SelectButton />
    </Select>
  );
}

import { z } from "zod";

export const globalSearchBody = z.object({
  key: z.string().max(250),
});
export type GlobalSearchBody = z.infer<typeof globalSearchBody>;

export const globalSearchResponse = z.object({
  brands: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      project: z.object({
        name: z.string(),
      }),
    }),
  ),
  projects: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
  requests: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      project: z.object({
        name: z.string(),
      }),
    }),
  ),
  users: z
    .array(
      z.object({
        email: z.string(),
        id: z.number(),
        name: z.string(),
      }),
    )
    .optional(), // ??? the same as on the backend
});
export type GlobalSearchResponse = z.infer<typeof globalSearchResponse>;
export type GlobalSearchResponseKey = keyof GlobalSearchResponse;

export type BrandsResponse = GlobalSearchResponse["brands"];
export type ProjectsResponse = GlobalSearchResponse["projects"];
export type RequestsResponse = GlobalSearchResponse["requests"];
export type UsersResponse = GlobalSearchResponse["users"];

export type SearchResultItem =
  | GlobalSearchResponse["brands"][number]
  | GlobalSearchResponse["projects"][number]
  | GlobalSearchResponse["requests"][number]
  | NonNullable<GlobalSearchResponse["users"]>[number];

export function isBrandResult(
  result: SearchResultItem,
): result is GlobalSearchResponse["brands"][number] {
  return "project" in result && !("email" in result);
}

export function isProjectResult(
  result: SearchResultItem,
): result is GlobalSearchResponse["projects"][number] {
  return !("project" in result) && !("email" in result);
}

export function isUserResult(
  result: SearchResultItem,
): result is NonNullable<GlobalSearchResponse["users"]>[number] {
  return "email" in result;
}

export function isRequestResult(
  result: SearchResultItem,
): result is GlobalSearchResponse["requests"][number] {
  return "project" in result && !("email" in result);
}

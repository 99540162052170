import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { isValidURL } from "@/utils/isValidUrl";

const linkSchema = z.object({
  link: z
    .string()
    .max(1000, "Link is too long.")
    .refine((val) => isValidURL(val), {
      message: "Link is invalid.",
    }),
});

export type LinkFormValues = z.infer<typeof linkSchema>;

export function useLinkForm() {
  const { control, formState, handleSubmit, reset, setError } =
    useForm<LinkFormValues>({
      defaultValues: {
        link: "",
      },
      resolver: zodResolver(linkSchema),
    });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ link: "" });
    }
  }, [formState, reset]);

  return { control, formState, handleSubmit, reset, setError };
}

import { twMerge } from "tailwind-merge";

import { useUser } from "@/hooks/useUser";

import { HelpSection } from "./components/help-section";
import { LogoSection } from "./components/logo-section";
import { NavItem } from "./components/nav-item";
import { TeamMembersNavItem } from "./components/team-members-nav-item";
import { NAV_ITEMS } from "./navItems";

interface NavItemsProps {
  isCollapsed: boolean;
}

function NavItems({ isCollapsed }: NavItemsProps) {
  const { data: user } = useUser();

  if (!user) return null;

  const userNavItems = NAV_ITEMS[user.user.role_name as keyof typeof NAV_ITEMS];

  if (!userNavItems) return null;

  return (
    <nav className="h-full overflow-y-auto">
      <ul className="flex flex-col gap-y-md">
        {userNavItems.map((item) => {
          if (item.href === "/users" && user.user.role_name === "team-leader") {
            return (
              <TeamMembersNavItem
                isCollapsed={isCollapsed}
                key={item.href}
                userId={user.user.id}
              />
            );
          }

          return (
            <NavItem
              href={item.href}
              icon={item.icon}
              isActive={item.href.includes("requests")}
              isCollapsed={isCollapsed}
              key={item.href}
              label={item.label}
            />
          );
        })}
      </ul>
    </nav>
  );
}

interface SidebarProps {
  isCollapsed: boolean;
  onToggleCollapse: (collapsed: boolean) => void;
}

export function Sidebar({ isCollapsed, onToggleCollapse }: SidebarProps) {
  const handleToggleCollapse = () => onToggleCollapse(!isCollapsed);

  return (
    <div
      className={twMerge(
        "fixed h-screen overflow-hidden border-r border-gray-200 bg-white pt-4xl",
        isCollapsed ? "w-8xl" : "w-[220px]",
      )}
    >
      <div className="flex h-full flex-col">
        <div className="flex-none pb-3xl">
          <LogoSection isCollapsed={isCollapsed} />
        </div>

        <div className="min-h-0 flex-1 px-xl">
          <NavItems isCollapsed={isCollapsed} />
        </div>

        <div className="flex-none px-xl pb-xl">
          <HelpSection
            isCollapsed={isCollapsed}
            onToggleCollapse={handleToggleCollapse}
          />
        </div>
      </div>
    </div>
  );
}

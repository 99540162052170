import { Separator } from "react-aria-components";
import { FormProvider } from "react-hook-form";

import type { CreateRequestFormData } from "./useCreateRequestForm";

import { AssetsSection } from "./assets-section";
import { BriefCreatorSection } from "./brief-creator-section";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { IntroSection } from "./intro-section";
import { SubmitWarningModal } from "./SubmitWarningModal";
import { TechnicalDetailsSection } from "./technical-details-section";
import { useCreateRequestForm } from "./useCreateRequestForm";

const onSubmit = (data: CreateRequestFormData) => console.log(data);

export function CreateRequestForm() {
  const { setShowWarningModal, showWarningModal, ...form } =
    useCreateRequestForm();

  return (
    <>
      <FormProvider {...form}>
        <div className="flex">
          <form
            className="mx-auto w-[1156px] rounded-xl bg-white"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Header />
            <div className="flex flex-col gap-3xl px-3xl py-2xl">
              <IntroSection />
              <Separator />
              <BriefCreatorSection />
              <Separator />
              <AssetsSection />
              <Separator />
              <TechnicalDetailsSection />
            </div>
            <Footer />
          </form>
        </div>
      </FormProvider>
      <SubmitWarningModal
        isOpen={showWarningModal}
        onOpenChange={setShowWarningModal}
      />
    </>
  );
}

import { Button } from "@/components/button";

export function Footer() {
  return (
    <div className="flex items-center justify-end gap-lg border-t border-gray-200 px-3xl py-2xl">
      <Button color="gray" isDisabled size="md" variant="secondary">
        Save Draft
      </Button>
      <Button size="md" type="submit" variant="primary">
        Submit
      </Button>
    </div>
  );
}

import { env } from "@/config/env";

import type { GlobalSearchResponse, SearchResultItem } from "../types";

import {
  isBrandResult,
  isProjectResult,
  isRequestResult,
  isUserResult,
} from "../types";

type SearchResultSectionProps = {
  results: GlobalSearchResponse[keyof GlobalSearchResponse];
  title: string;
};

function renderResultContent(result: SearchResultItem) {
  if (isUserResult(result)) {
    return (
      <a
        className="select-none px-xl py-md hover:bg-gray-200"
        href={`${env.VITE_BASE_URL}/users/${result.id}/edit`}
        key={result.id}
      >
        <div className="flex gap-1">
          <span className="shrink-0 font-semibold">{result.name} </span>
          <span className="line-clamp-5 font-normal">
            &lt;{result.email}&gt;
          </span>
        </div>
      </a>
    );
  }

  if (isRequestResult(result)) {
    return (
      <a
        className="select-none px-xl py-md hover:bg-gray-200"
        href={`${env.VITE_BASE_URL}/requests/${result.id}/edit`}
        key={result.id}
      >
        <div className="flex gap-1">
          <span className="shrink-0 font-semibold">{result.name} - </span>
          <span className="line-clamp-5 font-normal">
            {result.project.name}
          </span>
        </div>
      </a>
    );
  }

  if (isBrandResult(result)) {
    return (
      <a
        className="select-none px-xl py-md hover:bg-gray-200"
        href={`${env.VITE_BASE_URL}/brands/${result.id}/edit`}
        key={result.id}
      >
        <div className="flex gap-1">
          <span className="shrink-0 font-semibold">{result.name} - </span>
          <span className="line-clamp-5 font-normal">
            {result.project.name}
          </span>
        </div>
      </a>
    );
  }

  if (isProjectResult(result)) {
    return (
      <a
        className="select-none px-xl py-md hover:bg-gray-200"
        href={`${env.VITE_BASE_URL}/projects/${result.id}`}
        key={result.id}
      >
        <span className="font-semibold">{result.name}</span>
      </a>
    );
  }
}

export function SearchResultSection({
  results,
  title,
}: SearchResultSectionProps) {
  if (results?.length === 0 || !results) return null;

  return (
    <div className="flex flex-col text-sm">
      <span className="px-xl py-lg font-semibold">{title.toUpperCase()}</span>
      <div className="flex flex-col rounded-md">
        {results.map((result) =>
          renderResultContent(result as SearchResultItem),
        )}
      </div>
    </div>
  );
}

import type { Dispatch, SetStateAction } from "react";
import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import * as z from "zod";

const fileStatusSchema = z.enum(["error", "success", "uploading"]);

const fileSchema = z.instanceof(File);

const fileAssetSchema = z.object({
  awsKey: z.string().optional(),
  file: fileSchema,
  id: z.string(),
  status: fileStatusSchema,
});

const linkAssetSchema = z.object({
  id: z.string(),
  url: z.string().url(),
});

const createRequestFormSchema = z.object({
  /* eslint-disable perfectionist/sort-objects */
  requestName: z.string().min(1, { message: "Request name is required" }),
  designType: z.number({
    required_error: "Design type is required",
  }),
  brand: z.number({
    required_error: "Brand is required",
  }),
  // Brief creator
  briefCreatorType: z.enum(["detailed", "single"]),
  briefCreatorDetailed: z.object({
    additionalGuidelines: z.string(),
    brandGuidelines: z.string(),
    creativeGoal: z.string(),
    keyMessageAndCta: z.string(),
    scope: z.string(),
    finalBriefHTML: z.string(),
    finalBriefText: z.string(),
  }),
  briefCreatorSingle: z.object({
    requestDescriptionHTML: z.string(),
    requestDescriptionText: z.string(),
    textsForDesignHTML: z.string(),
    textsForDesignText: z.string(),
  }),
  // Assets and inspirations
  assets: z.array(z.union([fileAssetSchema, linkAssetSchema])),
  inspirations: z.array(z.union([fileAssetSchema, linkAssetSchema])),
  // Technical details
  creativeDirection: z.string(),
  sizes: z.string().min(1, { message: "Sizes are required" }),
  fileTypes: z.string().min(1, { message: "File types are required" }),
  preferredDesigners: z.array(z.string()),
  collaborators: z.array(z.string()),
  /* eslint-enable perfectionist/sort-objects */
});

export type CreateRequestFormData = z.infer<typeof createRequestFormSchema>;
export type FileStatus = z.infer<typeof fileStatusSchema>;
export type FileAsset = z.infer<typeof fileAssetSchema>;
export type LinkAsset = z.infer<typeof linkAssetSchema>;

export function useCreateRequestForm(): {
  setShowWarningModal: Dispatch<SetStateAction<boolean>>;
  showWarningModal: boolean;
} & UseFormReturn<CreateRequestFormData> {
  const [showWarningModal, setShowWarningModal] = useState(false);

  const form = useForm<CreateRequestFormData>({
    defaultValues: {
      /* eslint-disable perfectionist/sort-objects */
      requestName: "",
      designType: undefined,
      brand: undefined,
      briefCreatorType: "detailed",
      briefCreatorDetailed: {
        additionalGuidelines: "",
        brandGuidelines: "",
        creativeGoal: "",
        keyMessageAndCta: "",
        scope: "",
        finalBriefHTML: "",
        finalBriefText: "",
      },
      briefCreatorSingle: {
        requestDescriptionHTML: "",
        requestDescriptionText: "",
        textsForDesignHTML: "",
        textsForDesignText: "",
      },
      assets: [],
      inspirations: [],
      creativeDirection: undefined,
      sizes: "",
      fileTypes: "",
      preferredDesigners: [],
      collaborators: [],
      /* eslint-enable perfectionist/sort-objects */
    },
    resolver: zodResolver(
      createRequestFormSchema.superRefine((data, ctx) => {
        if (data.briefCreatorType === "detailed") {
          // Validate state without generating AI response
          if (
            data.briefCreatorDetailed.scope.length === 0 &&
            data.briefCreatorDetailed.finalBriefHTML.length === 0
          ) {
            setShowWarningModal(true);
          }

          // Validate state with generated AI response
          if (
            data.briefCreatorDetailed.scope.length > 0 &&
            data.briefCreatorDetailed.finalBriefHTML.length === 0
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                "The brief text is required. Please discard or regenerate to submit a request",
              path: ["briefCreatorDetailed", "finalBriefHTML"],
            });
          }
        } else if (data.briefCreatorType === "single") {
          // Validate single brief fields
          if (data.briefCreatorSingle.requestDescriptionHTML.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Request description is required",
              path: ["briefCreatorSingle", "requestDescriptionHTML"],
            });
          }
          if (data.briefCreatorSingle.textsForDesignHTML.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Text for design is required",
              path: ["briefCreatorSingle", "textsForDesignHTML"],
            });
          }
        }
      }),
    ),
  });

  // Display warning modal when user tries to leave page with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [form.formState.isDirty]);

  return {
    ...form,
    setShowWarningModal,
    showWarningModal,
  };
}

export function useCreateRequestFormContext() {
  return useFormContext<CreateRequestFormData>();
}

import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import * as z from "zod";

import apiClient from "@/lib/api/client";

const _userSchema = z.object({
  projects: z.array(
    z.object({
      all_credits: z.number(),
      available_credits: z.number(),
      billing_interval: z.string(),
      gd_directory_link: z.string(),
      id: z.number(),
      name: z.string(),
      next_billing_date: z.date(),
      price: z.string(),
      product: z.string(),
      product_id: z.number(),
    }),
  ),
  user: z.object({
    agreement: z.boolean(),
    avatar: z.string(),
    company: z.string(),
    company_id: z.number(),
    credits: z.number(),
    email: z.string(),
    id: z.number(),
    inactive: z.boolean(),
    name: z.string(),
    organization_id: z.number(),
    //password: z.string(),
    phone_number: z.string(),
    postal_code: z.string(),
    product_id: z.number(),
    project_id: z.number(),
    role: z.string(),
    role_display_name: z.string(),
    //remember_token: z.string(),
    role_id: z.number(),
    role_name: z.string(),
    state: z.string(),
    team_id: z.number(),
  }),
});

type UserSchema = z.infer<typeof _userSchema>;

export function useUser() {
  return useQuery<Response, DefaultError, UserSchema>({
    queryFn: async () => await apiClient.url(`/users/me`).get().json(),
    queryKey: ["user"],
  });
}

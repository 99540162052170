export function isValidURL(string: string): boolean {
  const res = string.match(
    /https?:\/\/(?:www\.|(?!www))[\da-z-]+\.\S{2,}|www\.[\da-z][\da-z-]+[\da-z]\.\S{2,}|www\.[\da-z]+\.\S{2,}/gi,
  );

  if (res !== null) {
    let givenURL;

    try {
      givenURL = new URL(string);
    } catch {
      return false;
    }

    return givenURL.protocol === "http:" || givenURL.protocol === "https:";
  }

  return false;
}

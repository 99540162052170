import React from "react";
import { twMerge } from "tailwind-merge";

import { useImageLoadingStatus } from "../hooks/use-image-loading-status";

const AvatarContext = React.createContext<{
  badgeId: string;
} | null>(null);

export type AvatarProps = {
  alt: string;
  colorless?: boolean;
  src?: string;
} & JSX.IntrinsicElements["div"];

export function Avatar({
  alt,
  children,
  className,
  colorless = false,
  src,
}: AvatarProps) {
  const badgeId = React.useId();
  const avatarId = React.useId();
  const ariaLabelledby = [avatarId, children ? badgeId : ""].join(" ");
  const status = useImageLoadingStatus(src);

  return (
    <AvatarContext.Provider value={{ badgeId }}>
      <div
        aria-labelledby={ariaLabelledby}
        className={twMerge([
          "group relative flex size-10 shrink-0 items-center justify-center rounded-lg @container",
          "outline outline-1 -outline-offset-1 outline-gray-300 ring-background",
          "[&.rounded-full>svg]:rounded-full [&>svg]:size-full [&>svg]:rounded-lg",
          "[&.rounded-full>img]:rounded-full [&>img]:size-full [&>img]:rounded-lg",
          className,
        ])}
        role="img"
      >
        {status === "loaded" ? (
          <img
            alt={alt}
            aria-hidden
            className="object-cover"
            id={avatarId}
            src={src}
          />
        ) : (
          <InitialAvatar alt={alt} colorless={colorless} id={avatarId} />
        )}
        {children}
      </div>
    </AvatarContext.Provider>
  );
}

function getInitials(name: string): string {
  // Split the name into parts
  const nameParts = name.trim().split(/\s+/);

  if (nameParts.length === 0 || !nameParts[0]) {
    return "";
  }

  if (nameParts.length === 1) {
    // Single word name - return first letter
    return nameParts[0].charAt(0).toUpperCase();
  }

  // Multiple words - take first letter of first and last word
  const firstInitial = nameParts[0].charAt(0);
  const lastInitial = nameParts.at(-1)?.charAt(0);

  return (firstInitial + lastInitial).toUpperCase();
}

function InitialAvatar({
  alt,
  id,
}: {
  alt: string;
  colorless: boolean;
  id: string;
}) {
  const initials = getInitials(alt);

  return (
    <svg
      aria-hidden
      aria-label={alt}
      className="bg-gray-100 font-medium text-gray-500"
      fill="currentColor"
      id={id}
      viewBox="0 0 24 24"
    >
      <text
        alignmentBaseline="middle"
        dominantBaseline="middle"
        dy=".125em"
        fontSize="65%"
        textAnchor="middle"
        x="50%"
        y="50%"
      >
        {initials}
      </text>
    </svg>
  );
}

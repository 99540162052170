import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import {
  Bell,
  ClipboardCheck,
  CreditCard,
  LogOut,
  Settings,
  User,
} from "lucide-react";
import { Button } from "react-aria-components";

import { AccessibleIcon } from "@/components/accessible-icon";
import { Avatar } from "@/components/avatar";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemLabel,
  MenuPopover,
  MenuSeparator,
  MenuTrigger,
} from "@/components/menu";
import { env } from "@/config/env";
import { useUser } from "@/hooks/useUser";
import apiClient from "@/lib/api/client";

import { Search } from "./search";

export function Navbar() {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  async function handleLogout() {
    try {
      await apiClient.url("/auth/logout").post().res();
      queryClient.clear();
      navigate({ to: "/login" });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  return (
    <header className="sticky top-0 z-50 flex h-[72px] flex-row-reverse items-center justify-between border-b border-gray-200 bg-white px-4xl py-xl">
      <div className="flex gap-xl">
        <div className="flex gap-xs">
          <Search />
          <Button className="grid h-full w-5xl place-items-center text-gray-500">
            <Bell size={20} />
          </Button>
        </div>
        <MenuTrigger>
          <MenuButton noIndicator variant="unstyled">
            <Avatar alt={user?.user.name ?? ""} className="rounded-full" />
          </MenuButton>
          <MenuPopover offset={22}>
            <Menu>
              <MenuItem href={`${env.VITE_BASE_URL}/user/profile`}>
                <AccessibleIcon>
                  <User />
                </AccessibleIcon>
                <MenuItemLabel>Profile</MenuItemLabel>
              </MenuItem>
              {/* role_id = 2 is an owner */}
              {user?.user.role_id === 2 && (
                <MenuItem href={`${env.VITE_BASE_URL}/profile/billing`}>
                  <AccessibleIcon>
                    <CreditCard />
                  </AccessibleIcon>
                  <MenuItemLabel>Billing</MenuItemLabel>
                </MenuItem>
              )}
              <MenuItem
                href={`${env.VITE_BASE_URL}/profile/notification-settings`}
              >
                <AccessibleIcon>
                  <Settings />
                </AccessibleIcon>
                <MenuItemLabel>Notification Settings</MenuItemLabel>
              </MenuItem>
              <MenuSeparator />
              <MenuItem href={`${env.VITE_BASE_URL}/make-a-suggestion`}>
                <AccessibleIcon>
                  <ClipboardCheck />
                </AccessibleIcon>
                <MenuItemLabel>Make a suggestion</MenuItemLabel>
              </MenuItem>
              <MenuSeparator />
              <MenuItem onAction={handleLogout}>
                <AccessibleIcon>
                  <LogOut />
                </AccessibleIcon>
                <MenuItemLabel>Log out</MenuItemLabel>
              </MenuItem>
            </Menu>
          </MenuPopover>
        </MenuTrigger>
      </div>
    </header>
  );
}

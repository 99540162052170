import type { UseFormReturn } from "react-hook-form";

import { nanoid } from "nanoid";

import type {
  CreateRequestFormData,
  FileAsset,
  LinkAsset,
} from "../../useCreateRequestForm";

import { uploadFile } from "../utils/uploadUtils";

type AssetField = "assets" | "inspirations";

export function useFileUpload(
  field: AssetField,
  assets: (FileAsset | LinkAsset)[],
  setError: (error: null | string) => void,
  projectId: number,
  methods: UseFormReturn<CreateRequestFormData>,
): {
  handleFileUpload: (uploadedFiles: File[]) => Promise<void>;
} {
  const handleFileUpload = async (uploadedFiles: File[]) => {
    const existingFileNames = new Set(
      assets
        .filter((asset): asset is FileAsset => "file" in asset)
        .map((asset) => asset.file.name),
    );

    const duplicateFiles = uploadedFiles.filter((file) =>
      existingFileNames.has(file.name),
    );

    if (duplicateFiles.length > 0) {
      setError(
        `Cannot upload duplicate file${duplicateFiles.length > 1 ? "s" : ""}: ${duplicateFiles.map((f) => f.name).join(", ")}`,
      );

      return;
    }

    const newAssets = uploadedFiles.map((file) => ({
      awsKey: "",
      file,
      id: nanoid(),
      status: "uploading" as const,
    }));

    methods.setValue(field, [...newAssets, ...assets]);

    for (const newFile of newAssets) {
      await uploadFile(newFile, setError, projectId, methods, field);
    }
  };

  return { handleFileUpload };
}

import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";

import { env } from "@/config/env";

const BASE_URL = env.VITE_BASE_API_URL;

const baseApi = wretch(`${BASE_URL}/api`)
  .addon(QueryStringAddon)
  .options({
    credentials: "include",
  })
  .catcher(401, async () => {
    handleLogout();
  })
  .catcherFallback((error) => {
    throw error.json;
  });

export default baseApi;

async function handleLogout() {
  try {
    // Should logout on the PHP side and destroy the session
    await wretch(`${BASE_URL}/logout`)
      .options({ credentials: "include" })
      .post()
      .res();
  } catch (error) {
    console.error("Logout error:", error);
  } finally {
    window.location.href = "/login";
  }
}

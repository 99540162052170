import type {
  InputProps,
  SearchFieldProps as RACSearchFieldProps,
} from "react-aria-components";

import { CircleXIcon, SearchIcon } from "lucide-react";
import { Group, SearchField as RACSearchField } from "react-aria-components";

import { Button } from "../button";
import { Input } from "../field";
import { composeTailwindRenderProps, inputFieldStyle } from "../utils";

export type SearchFieldProps = RACSearchFieldProps;

export function SearchField(props: SearchFieldProps) {
  return (
    <RACSearchField
      {...props}
      className={composeTailwindRenderProps(props.className, inputFieldStyle)}
    ></RACSearchField>
  );
}

export function SearchInput(
  props: {
    onClear: () => void;
  } & InputProps,
) {
  return (
    <Group
      className={[
        "isolate",
        "grid",
        "grid-cols-[calc(theme(size.5)+20px)_1fr_calc(theme(size.5)+20px)]",
        "sm:grid-cols-[calc(theme(size.4)+20px)_1fr_calc(theme(size.4)+20px)]",
      ].join(" ")}
      data-ui="control"
    >
      <SearchIcon
        className="z-10 col-start-1 row-start-1 place-self-center text-gray-500"
        size={15}
      />

      <Input
        {...props}
        className={composeTailwindRenderProps(props.className, [
          "[&::-webkit-search-cancel-button]:hidden",
          "col-span-full",
          "row-start-1",
          "pe-10",
          "sm:pe-9",
          "ps-10",
          "sm:ps-8",
        ])}
      />
      <Button
        className="-col-end-1 row-start-1 place-self-center group-empty:invisible"
        isIconOnly
        onPress={props.onClear}
        size="sm"
        variant="plain"
      >
        <CircleXIcon
          aria-label="Clear"
          className="z-10 col-start-1 row-start-1 place-self-center text-gray-500"
        />
      </Button>
    </Group>
  );
}

import { useQuery } from "@tanstack/react-query";
import { SearchIcon, X } from "lucide-react";
import { useState } from "react";
import { DialogTrigger, Separator } from "react-aria-components";

import { AccessibleIcon } from "@/components/accessible-icon";
import { Button } from "@/components/button";
import { Dialog } from "@/components/dialog";
import { MenuButton } from "@/components/menu";
import { Popover } from "@/components/popover";
import { SearchField, SearchInput } from "@/components/search-field";
import { useDebounce } from "@/hooks/useDebounce";
import apiClient from "@/lib/api/client";

import type { GlobalSearchBody, GlobalSearchResponse } from "./types";

import { SearchResultSection } from "./components/SearchResultSection";

function useGlobalSearch(query: string) {
  return useQuery({
    enabled: Boolean(query),
    queryFn: async () =>
      await apiClient
        .url("/search")
        .post({ key: query } as GlobalSearchBody)
        .json<GlobalSearchResponse>(),
    queryKey: ["search", query],
  });
}

export function Search() {
  const [inputValue, setInputValue] = useState("");
  const debouncedQuery = useDebounce(inputValue);

  const { data } = useGlobalSearch(debouncedQuery);

  const handleSearch = (value: string) => {
    if (value.trim()) {
      setInputValue(value);
    } else {
      setInputValue("");
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setInputValue("");
    }
  };

  return (
    <DialogTrigger onOpenChange={handleOpenChange}>
      <>
        <MenuButton isIconOnly noIndicator variant="plain">
          <AccessibleIcon>
            <SearchIcon />
          </AccessibleIcon>
        </MenuButton>
        <Popover className="min-w-[320px] max-w-[480px] pt-xs shadow-none">
          <Dialog className="flex h-full flex-col">
            {({ close }) => (
              <>
                <div className="flex flex-col gap-xl pb-3xl">
                  <div className="flex items-center justify-between px-xl py-md">
                    <span className="text-md font-semibold text-gray-900">
                      Global search
                    </span>
                    <Button onPress={close} variant="unstyled">
                      <AccessibleIcon>
                        <X className="text-gray-400" size={16} />
                      </AccessibleIcon>
                    </Button>
                  </div>
                  <SearchField className="flex-1 px-xl">
                    <SearchInput
                      className="flex items-end gap-xl"
                      onChange={(e) => handleSearch(e.target.value)}
                      onClear={() => setInputValue("")}
                      placeholder="florence@nlc.com"
                      value={inputValue}
                    />
                  </SearchField>
                  {inputValue &&
                    data &&
                    !Object.values(data).some(
                      (sectionResults) => sectionResults.length > 0,
                    ) && (
                      <div className="mx-auto py-lg text-sm font-semibold">
                        No records found
                      </div>
                    )}
                  {data &&
                    Object.values(data).some(
                      (sectionResults) => sectionResults.length > 0,
                    ) && (
                      <div className="flex flex-col">
                        <Separator className="w-full border-t border-gray-200" />
                        <div className="max-h-[600px] overflow-y-auto">
                          <SearchResultSection
                            results={data.projects}
                            title="Accounts"
                          />
                          <SearchResultSection
                            results={data.brands}
                            title="Brands"
                          />
                          <SearchResultSection
                            results={data.requests}
                            title="Requests"
                          />
                          <SearchResultSection
                            results={data.users}
                            title="Users"
                          />
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
          </Dialog>
        </Popover>
      </>
    </DialogTrigger>
  );
}

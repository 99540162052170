import React from "react";

type ImageLoadingStatus = "error" | "idle" | "loaded" | "loading";

export function useImageLoadingStatus(src?: string): ImageLoadingStatus {
  const [loadingStatus, setLoadingStatus] =
    React.useState<ImageLoadingStatus>("idle");

  React.useLayoutEffect(() => {
    if (!src) {
      setLoadingStatus("error");

      return;
    }

    let isMounted = true;
    const image = new window.Image();

    const updateStatus = (status: ImageLoadingStatus) => () => {
      if (!isMounted) return;
      setLoadingStatus(status);
    };

    setLoadingStatus("loading");
    image.addEventListener("load", updateStatus("loaded"));
    image.addEventListener("error", updateStatus("error"));
    image.src = src;

    return () => {
      isMounted = false;
    };
  }, [src]);

  return loadingStatus;
}

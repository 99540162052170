import type {
  MenuItemProps as RACMenuItemProps,
  MenuProps as RACMenuProps,
  SectionProps,
} from "react-aria-components";

import {
  Collection,
  composeRenderProps,
  Header,
  PopoverContext,
  Menu as RACMenu,
  MenuItem as RACMenuItem,
  Section,
  Separator,
  useSlottedContext,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { ButtonWithoutAsChildProps } from "../button";
import type { PopoverProps } from "../popover";

import { Button } from "../button";
import { CheckIcon, ChevronDownIcon, ChevronRightIcon } from "../icons";
import { Popover } from "../popover";
import { Small } from "../text";
import { composeTailwindRenderProps } from "../utils";

export { MenuTrigger, SubmenuTrigger } from "react-aria-components";

type MenuButtonProps = {
  noIndicator?: boolean;
} & ButtonWithoutAsChildProps;

export function MenuButton({
  children,
  className,
  noIndicator,
  variant,
  ...props
}: MenuButtonProps) {
  // Set`unstyled` as the default button style
  if (!variant) {
    variant = "unstyled";
  }

  return (
    <Button
      {...props}
      className={
        composeTailwindRenderProps(className, [
          "gap-1",
          "pressed:scale-[0.97]",
          "group-data-[ui=button-group]:pressed:scale-100",
          "group-data-[ui=button-group]:pressed:opacity-100",
          variant === "unstyled" ? "" : "px-2.5",
        ]) as string
      }
      variant={variant}
    >
      {(renderProps) => (
        <>
          {typeof children === "function" ? children(renderProps) : children}
          {!noIndicator && (
            <ChevronDownIcon
              className={twMerge(
                !!children && "ms-1",
                "[.justify-start_&]:ms-auto",
              )}
            />
          )}
        </>
      )}
    </Button>
  );
}

export function MenuPopover({ className, placement, ...props }: PopoverProps) {
  const popoverContext = useSlottedContext(PopoverContext)!;
  const isSubmenu = popoverContext?.trigger === "SubmenuTrigger";

  return (
    <Popover
      {...props}
      className={composeTailwindRenderProps(
        className,
        twMerge(
          "max-w-[240px]",
          "rounded-md",
          "min-w-[max(112px,var(--trigger-width))]",
          "has-[[data-ui=item]_[data-ui=icon]]:min-w-[max(200px,var(--trigger-width))]",
          "has-[[data-ui=item]_kbd]:min-w-[max(176px,var(--trigger-width))]",
        ),
      )}
      placement={placement ?? (isSubmenu ? undefined : "bottom")}
    />
  );
}
export function Menu<T extends object>(props: RACMenuProps<T>) {
  return (
    <RACMenu
      {...props}
      className={twMerge(
        "flex max-h-[inherit] flex-col overflow-auto outline-none",
        "py-1 has-[header]:px-2 has-[header]:pt-0",

        // MenuItem style
        "[&_[data-ui=item]]:flex-1",
        "[&_[data-ui=item]]:grid",
        "[&_[data-ui=item]:has([data-ui=label])]:grid-cols-[16px_1fr_minmax(50px,max-content)]",
        "[&_[data-ui=item]]:items-center",
        "[&_[data-ui=item]]:gap-x-md",

        // icon
        "[&_[data-ui=item]>[data-ui=icon]:not([class*=text-])]:text-gray-500",
        "[&_[data-ui=item][data-destructive]>[data-ui=icon]]:text-destructive/75",
        "[&:has([data-ui=icon]+[data-ui=label])_[data-ui=item]>[data-ui=icon]:not([class*=size-])]:size-4",
        "[&:has([data-ui=icon]+[data-ui=label])_[data-ui=item]>[data-ui=icon]]:col-start-1",

        "[&_[data-ui=item]>[data-ui=label]]:col-span-full",
        "[&:has([data-ui=icon]+[data-ui=label])_[data-ui=item]>[data-ui=label]]:col-start-2",

        // kbd
        "[&:has([data-ui=kbd])_[data-ui=item]>[data-ui=label]]:col-end-[-2]",
        "[&_[data-ui=item]>[data-ui=kbd]]:col-span-1",
        "[&_[data-ui=item]>[data-ui=kbd]]:row-start-1",
        "[&_[data-ui=item]>[data-ui=kbd]]:col-start-3",
        "[&_[data-ui=item]>[data-ui=kbd]]:justify-self-end",
        "[&_[data-ui=item][data-destructive]>[data-ui=kbd]]:text-destructive",

        // Description
        "[&_[data-ui=item]>[data-ui=description]]:col-span-full",
        "[&:has([data-ui=kbd])_[data-ui=item]>[data-ui=description]]:col-end-[-2]",
        "[&:has([data-ui=icon]+[data-ui=label]+[data-ui=description])_[data-ui=item]>[data-ui=description]]:col-start-2",
        props.className,
      )}
    />
  );
}

export function SubMenu<T extends object>(
  props: { "aria-label": string } & RACMenuProps<T>,
) {
  return <Menu {...props} />;
}

export function MenuSeparator({ className }: { className?: string }) {
  return (
    <Separator
      className={twMerge(
        "my-[1px] w-full self-center border-t border-gray-200",
        className,
      )}
    />
  );
}

type MenuItemProps = {
  destructive?: true;
} & RACMenuItemProps;

export function MenuItem({ destructive, ...props }: MenuItemProps) {
  return (
    <RACMenuItem
      {...props}
      className={composeRenderProps(
        props.className,
        (className, { isDisabled, isFocused }) =>
          twMerge([
            "group flex cursor-default select-none items-center gap-x-md outline-none",
            "px-[10px] mx-1 py-[9px] [&[data-selection-mode]]:px-2",
            "rounded-md",
            "text-sm font-medium text-gray-700",
            isDisabled && "opacity-50",
            isFocused && "bg-zinc-100 dark:bg-zinc-700",
            destructive && "text-destructive",
            className,
          ]),
      )}
    >
      {composeRenderProps(
        props.children,
        (children, { isHovered, isSelected, selectionMode }) => (
          <>
            {selectionMode !== "none" && (
              <span
                className={twMerge(
                  "flex w-4 shrink-0 self-start",
                  isSelected && "mt-[5px]",
                )}
                data-ui="icon"
              >
                {isSelected && <CheckIcon className="size-4" />}
              </span>
            )}

            <div
              data-destructive={destructive ?? undefined}
              data-hovered={isHovered}
              data-ui="item"
            >
              {children}
            </div>
            {/* Submenu indicator */}
            <ChevronRightIcon className="-me-2 hidden size-4 group-data-[has-submenu]:inline-block" />
          </>
        ),
      )}
    </RACMenuItem>
  );
}

export function MenuItemLabel({
  className,
  ...props
}: JSX.IntrinsicElements["span"]) {
  return (
    <span
      className={twMerge("mb-0 truncate", className)}
      data-ui="label"
      slot="label"
      {...props}
    />
  );
}

export function MenuItemDescription({
  className,
  ...props
}: JSX.IntrinsicElements["span"]) {
  return (
    <Small
      className={className}
      data-ui="description"
      slot="description"
      {...props}
    />
  );
}

export interface MenuSectionProps<T> extends SectionProps<T> {
  title?: string;
}

export function MenuSection<T extends object>({
  className,
  ...props
}: MenuSectionProps<T>) {
  return (
    <Section
      className={twMerge(
        "[&:first-child]:-mt-[1px]",
        "[&:not(:first-child)]:my-1.5",
        "[&:not(:first-child)]:border-t [&:not(:first-child)]:border-t-border/40",
        // Header
        // selectionMode = none
        "[&_header]:px-3",
        // has icon
        "[&:has([data-ui=item]>[data-ui=icon]+[data-ui=label])_header]:px-4",
        // electionMode !== none
        "[&:has([role=menuitemradio])_header]:ps-8",
        // has icon
        "[&:has([role=menuitemradio]>[data-ui=item]>[data-ui=icon]+[data-ui=label])_header]:ps-9",
        className,
      )}
    >
      <Header
        className={twMerge(
          "sticky z-10 bg-white dark:bg-zinc-800 truncate pt-2 text-xs/4 text-muted",
          "top-[0px] -mx-[1px] rounded-lg backdrop-blur-md",
        )}
      >
        {props.title}
      </Header>
      <Collection items={props.items}>{props.children}</Collection>
    </Section>
  );
}

import { X } from "lucide-react";
import { AlertCircle } from "lucide-react";
import { Button } from "react-aria-components";

type AlertProps = {
  description: string;
  onClick?: () => void;
  title: string;
};

export function Alert({ description, onClick, title }: AlertProps) {
  return (
    <div className="flex gap-xl rounded-xl border border-brand-300 bg-brand-50 p-xl">
      <div className="grid size-4xl shrink-0 place-items-center rounded-full bg-brand-100">
        <AlertCircle className="text-brand-600" size={16} />
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-medium text-brand-700">{title}</span>
        <span className="text-sm text-brand-600">{description}</span>
      </div>
      <Button
        className="ml-auto grid size-2xl place-items-center"
        onPress={onClick}
      >
        <X className="text-brand-400" size={12} />
      </Button>
    </div>
  );
}
